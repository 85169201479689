<template>
  <div class="Header has-background-grey-darker">
    <div class="p-a-m is-flex is-aligned-end">
      <the-guided-tour />
    </div>
    <div class="Header__mid columns is-mobile is-multiline m-b-none">
      <div class="column is-10-mobile is-4-tablet">
        <router-link :to="{ name: 'settings.personal' }" class="Header__mid__user has-text-white">
          <round-avatar
            :src="currentUser.avatar.cropped"
            :acronym-fallback="fullName"
            class="m-r-m"
          />

          <div>
            <div class="Header__User-name">
              {{ fullName }}
            </div>

            <div class="Header__User-companyName">
              {{ firstOrganisation.name }}
            </div>
          </div>
        </router-link>
        <v-button
          v-if="impersonated"
          class="is-outlined is-white m-t-m"
          data-testid="deImpersonateUserTrigger"
          @click="deImpersonateUser"
        >
          {{ $t('components.the_header.logout_from_impersonated') }}
        </v-button>
      </div>
      <div class="column is-expanded is-order-2-mobile">
        <the-trial-period-warning />
      </div>
      <div class="Header__mid__logo column is-narrow is-2-mobile">
        <router-link to="/">
          <img
            :src="$asset('/images/branding/hf_logo_full_white.svg')"
            :alt="$t('general.hypefactors')"
            class="is-hidden-mobile"
          >
          <img
            :src="$asset('/images/branding/hf_logo_white.svg')"
            :alt="$t('general.hypefactors')"
            class="is-hidden-tablet"
          >
        </router-link>
      </div>
    </div>

    <div
      v-clickoutside="clickedOutside"
      class="is-position-relative"
    >
      <navigation-bar
        ref="navigationBar"
        :links="navigation"
        :active-link="activeLink"
        type="primary"
        @menu-selected="handleMenuSelection"
      >
        <li class="m-l-a">
          <restricted-button
            :to="{ name: 'logout' }"
            :permission="!impersonated"
            data-testid="logoutLink"
            action-class="is-nav button is-white is-plain"
          >
            <v-icon type="lock" />
            {{ $t('general.logout') }}
          </restricted-button>
        </li>
      </navigation-bar>

      <navigation-bar
        v-if="nestedLinks.length&& activeLink !== 'staff'"
        :links="nestedLinks"
        type="secondary"
      />

      <transition
        mode="out-in"
        name="fade-fast"
      >
        <navigation-mega-menu
          v-if="activeLink === 'staff'"
          :links="nestedNavigation.staff"
          @link-selected="handleMenuSelection(null)"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NavigationBar from '@/components/navigation/NavigationBar'
import _get from 'lodash/get'
import NavigationMegaMenu from '@/components/navigation/NavigationMegaMenu'
import TheTrialPeriodWarning from '@/components/TheTrialPeriodWarning'
import TheGuidedTour from './TheGuidedTour'

export default {
  components: {
    TheGuidedTour,
    TheTrialPeriodWarning,
    NavigationMegaMenu,
    NavigationBar
  },

  data () {
    return {
      activeLink: null
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'firstOrganisation', 'brands', 'activeBrand', 'fullName', 'isStaffMember', 'impersonated']),

    currentSection () {
      if (!Array.isArray(this.$route.matched) && this.$route.matched.length === 0) return []
      const nestedRoutes = this.$route.matched
        .slice(0)
        .reverse()
        .find(r => typeof r.meta.section !== 'undefined')

      return _get(nestedRoutes, 'meta.section', null)
    },

    nestedLinks () {
      if (!this.currentSection) return []
      return this.nestedNavigation[this.currentSection] || []
    },

    navigation () {
      return [
        { label: this.$t('general.dashboard'), to: { name: 'dashboard.dashboard' } },
        { label: this.$t('components.the_header.monitor'), to: { name: 'streams.list' } },
        { label: this.$t('components.the_header.measure'), to: { name: 'performance' } },
        { label: this.$t('components.the_header.report'), to: { name: 'reports' } },
        { label: this.$t('components.the_header.connect'), to: { name: 'connect' } },
        { label: this.$t('components.the_header.create'), to: { name: 'story.list' } },
        // { label: this.$t('components.the_header.emails'), to: { name: 'emails.list' } },
        // { label: this.$t('general.social'), to: { name: 'social' } },
        // { label: this.$t('components.the_header.files'), to: { name: 'nowhere' }, disabled: true },
        { label: 'Trends', to: { name: 'trends.index' }, isHidden: !this.isStaffMember },
        { label: this.$t('general.settings'), to: { name: 'settings' } },
        { label: this.$t('components.the_header.staff'), megamenu: true, key: 'staff', isHidden: !this.isStaffMember }
      ]
    },

    nestedNavigation () {
      return {
        relations: [
          { label: this.$t('components.the_header.discover'), to: { name: 'connect.discover' } },
          { label: this.$t('general.relations'), to: { name: 'connect.relations' } },
          { label: this.$t('components.the_header.groups'), to: { name: 'connect.groups' } },
          { label: this.$t('components.the_header.facts'), to: { name: 'connect.facts' } }
        ],
        performance: [
          { label: this.$t('components.the_header.facts'), to: { name: 'performance.facts' } },
          { label: this.$t('components.the_header.kpis'), to: { name: 'performance.kpi' } },
          { label: this.$t('general.coverage'), to: { name: 'performance.coverage' } },
          {
            label: this.$t('components.the_header.add_clipping'),
            to: { name: 'performance.coverage.clipping.prepare' },
            exact: true
          }
        ],
        streams: [
          { label: this.$t('general.streams'), to: { name: 'streams.list' } },
          {
            label: this.$t('forms.create_stream'),
            to: { name: 'streams.create' },
            callback: () => {
              this.$ma.trackEvent({
                action: 'Stream Creation Intent',
                properties: { isFromNavigation: true }
              })
            }
          }
        ],
        stories: [
          { label: this.$t('components.the_header.stories'), to: { name: 'story.list' } },
          {
            label: this.$t('components.the_header.newsrooms'),
            to: { name: 'story.newsrooms' }
          }
          // {
          //   label: this.$t('components.the_header.employee_advocacy'),
          //   to: { name: 'stories.email', query: { type: 'social-share' } }
          // }
        ],
        report: [
          { label: this.$t('components.the_header.report_archive'), to: { name: 'reports.list' } }
        ],
        social: [
          { label: this.$t('components.the_header.facts'), to: { name: 'social.facts' } }
        ],
        emails: [
          // { label: this.$t('components.the_header.sent'), to: { name: 'emails.list' }, exact: true },
          // { label: this.$t('components.the_header.compose_email'), to: { name: 'emails.new' }, exact: true }
        ],
        settings: [
          { label: this.$t('general.company'), to: { name: 'settings.company' } },
          { label: this.$t('general.brand'), to: { name: 'settings.brand' } },
          { label: this.$t('components.the_header.personal'), to: { name: 'settings.personal' } }
          // { label: this.$t('components.the_header.billing'), to: { name: 'settings.billing' } }
        ],
        staff: {
          title: this.$t('components.the_header.megamenu.staff.title'),
          children: [
            {
              title: this.$t('components.the_header.megamenu.staff.media_outlet_management.title'),
              children: [
                { label: this.$t('general.media_outlets'), to: { name: 'staff.media-outlets' } },
                { label: this.$t('components.the_header.megamenu.staff.mentions_qa'), to: { name: 'staff.qa_mentions' } },
                { label: this.$t('components.the_header.megamenu.staff.print_upload'), to: { name: 'staff.print_upload' } }
              ]
            },
            {
              title: 'Connect',
              children: [
                {
                  label: this.$t('components.the_header.megamenu.staff.media_profiles_management.search_media_profiles'),
                  to: { name: 'staff.connect.media-profiles' }
                },
                {
                  label: this.$t('components.the_header.megamenu.staff.media_profiles_management.add_media_profiles'),
                  to: { name: 'staff.connect.media-profiles.create' }
                },
                // {
                //   label: this.$t('components.the_header.megamenu.staff.media_profiles_management.import_media_profiles'),
                //   to: { name: 'staff.connect.media-profiles.import' }
                // },
                {
                  label: this.$t('components.the_header.megamenu.staff.media_profiles_management.import_relations'),
                  to: { name: 'staff.connect.relations.import' }
                },
                {
                  label: this.$t('components.the_header.megamenu.staff.media_profiles_management.delete_relations'),
                  to: { name: 'staff.connect.relations.delete' }
                }
              ]
            },
            {
              title: this.$t('components.the_header.megamenu.staff.user_management.title'),
              children: [
                { label: this.$t('components.the_header.megamenu.staff.user_management.add_user'), to: { name: 'staff.create-user' } },
                { label: this.$t('components.the_header.megamenu.staff.user_management.delete_user'), to: { name: 'staff.delete-user' } },
                { label: this.$t('components.the_header.megamenu.staff.user_management.restore_user'), to: { name: 'staff.restore-user' } },
                { label: this.$t('components.the_header.megamenu.staff.user_management.impersonate-user'), to: { name: 'staff.impersonate-user' } }
              ]
            },
            {
              title: this.$t('components.the_header.megamenu.staff.newsroom_management.title'),
              children: [
                { label: this.$t('general.newsrooms'), to: { name: 'staff.newsrooms' } },
                {
                  label: this.$t('components.the_header.megamenu.staff.newsroom_management.authenticate_twitter_profiles'),
                  to: { name: 'staff.authenticate-twitter-profiles' }
                }
              ]
            },
            {
              title: this.$t('components.the_header.megamenu.staff.organisation_management.title'),
              children: [
                { label: this.$t('general.organisations'), to: { name: 'staff.organisations' } },
                {
                  label: this.$t('components.the_header.megamenu.staff.organisation_management.enterprise_requests'),
                  to: { name: 'staff.enterprise_requests' }
                },
                { label: 'Set Email Logo', to: { name: 'staff.set_email_logo' } }
              ]
            },
            {
              title: this.$t('components.the_header.megamenu.staff.brand_management.title'),
              children: [
                { label: this.$t('components.the_header.megamenu.staff.brand_management.restore_brand'), to: { name: 'staff.restore-brand' } },
                { label: this.$t('components.the_header.megamenu.staff.brand_management.manage_streams'), to: { name: 'staff.manage_streams' } }
              ]
            }
          ]
        }
      }
    }
  },

  methods: {
    ...mapActions(['deImpersonateUser']),
    handleMenuSelection (item) {
      // if item is already selected, toggle it off by setting null
      this.activeLink = this.activeLink === item ? null : item
    },
    clickedOutside (e) {
      this.handleMenuSelection(null)
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Header {
  &__mid {
    padding: 0 $padding-medium;
    margin-top: 0;
    @include tablet {
      padding: 0 $padding 0 0;
      margin-left: $brands-sidebar-width;

      @include rtl {
        padding: 0 0 $padding 0;
        margin-right: $brands-sidebar-width;
        margin-left: unset;
      }
    }
  }

  &__mid {
    &__user {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__logo {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        display: block;
        height: 50px;
      }
    }

    .Header__User {
      &-name {
        font-size: $size-4;
        font-weight: bold;
      }

      &-companyName {
        font-size: $hf__font-label;
      }
    }
  }
}
</style>
