import { loadScript, removeElement } from './index'

/**
 * Adds an active campaign tracking code
 * @param {string} trackingEmail
 * @param {string} from
 * @param {string} to
 * @return {Promise<any>}
 */
export function addActiveCampaignTracking (trackingEmail = '', from, to) {
  const scriptSrc = `//trackcmp.net/visit?actid=${process.env.VUE_APP_ACTIVE_CAMPAIGN_ID}&e=${encodeURIComponent(trackingEmail)}&r=${encodeURIComponent(from)}&u=${encodeURIComponent(to)}`
  return loadScript(scriptSrc, 'active-campaign').catch(() => {})
}

/**
 * Removes the active campaign tracking code
 */
export function removeActiveCampaingTracking () {
  removeElement(document.getElementById('active-campaign'))
}
